import React from "react"
import Layout from "../components/layout"
import Image from '../components/image'
import layoutStyle from '../components/layout.module.scss'
import resume from '../res/Kevan Mordan Resume.pdf'

const ResumePage = () => {

    return (
        <Layout page='Resume'>
            <h1>Resume</h1>
            <a style={{maxWidth:32, marginLeft:"4rem"}} className={layoutStyle.content} href={resume} download> 
                <Image/>
            </a>
            <div>
            <h3>Profile</h3>
            I am a well-traveled military officer with 7+ years of program management, technical experience, and full-stack development. 
            Throughout my career, I have demonstrated my ability to excel in challenging team environments, 
            drive agile process improvements, and focus on technical solution implementation.
            </div>
            <h3 className={layoutStyle.content}>Key Skills </h3>
            JS, TS, Java, C, Go, SQL, React, Spring, Docker, Jenkins, PCF, AWS, Git
            <br/>
            Test-Driven Development, User-Centered Design, Team Management & Integration, Documentation & Standardization
            <h3>Professional Experience</h3>
            <h5>Lead Software Engineer/Manager - USAF, 805 CTS AFWERX Vegas, (July 2018 - Present)</h5>
            Leads 23 people and two development teams in agile and Test-Driven Development methodologies; creating multi-domain applications to unify Air Force operations.
            Direct teams as lead software developer of chat application, enabling situational awareness across multiple classifications and domains; designed multi-layer security database, using Security-Enhanced Linux and GraphQL broker to interface with a PostgreSQL database.
            Coordinate fielding software around the world in classified environments; in use in real world operations for defense, coordination, and command and control of national assets.
            Teach and train junior members in software development practices; interview potential candidates and advise senior leadership on developer hiring decisions and training requirements. 
            Coordinate and prioritize application development with senior leadership; posture leadership to make informed decisions on applications to add to multi-domain the multi-domain operations center portfolio.
            Operationally assess new applications with user feedback and organization requirements; lead 4-person security team to provide vulnerability assessments, meeting Air Force needs and security requirements. 
            <div className={layoutStyle.content}>
            <h5>Lead Software Engineer - USAF, Pivotal Labs, Massachusetts (Jan 2018 - July 2018)</h5>
            Led 12-person agile software development team; directed Test Driven Development and Extreme Programming in full-stack software design and implementation of Mission Reporting Application. 
            Directed continuous integration and delivery pipeline, enabling authorization to deploy to classified environments; provided test coverage and security tools.
            Led user feedback and process improvement; identified and validated effective solutions for user needs; provided on site onsite support and development around the world.
            </div>
            <div className={layoutStyle.content}>
            <h5>Program Manager - USAF, Nellis AFB, Nevada (Apr 2016 - Dec 2017)</h5>
            Coordinated team of 28; led IT management and implemented strategic planning processes for training scenario development, infrastructure upgrades, and cyber operations.
            Technical manager of $12M IT infrastructure, led 3 teams in $4M upgrade, enabling advanced operational training of US/NATO warfighters; drove degraded capability processes and tactics development.
            Assessed operational capability of Cyber Defense and Attack Teams, certifying 261 US and NATO Cyber Operators with advanced readiness qualifications.
            </div>
            <div className={layoutStyle.content}>
            <h3>Recognition & Awards</h3>
            Led Wing Innovative Team of the Year (2018/2019)<br/>
            Led Wing Team of the Year (2018)<br/>
            Junior Manager of the Year (2017)<br/>
            'Information Dominance' award, Air Force Cyber Capability Excellence Award (2016)<br/>
            'Honor Graduate' award, Air Force Cyber Training; graduated #1/15 (2016)<br/>
            </div>
            <div className={layoutStyle.content}>
            <h3>Education & Credentials</h3>
            MS in Computer Science (currently in progress)<br/>
            Georgia Institute of Technology - Atlanta, GA - Enrolled: January 2019<br/>
            Air Force Weapon System Certification AOCIQTCOM - Hurlburt Field AFB, FL - Graduated August 2016<br/>
            Security+ - CompTIA - 2016<br/>
            USAF Cyberspace Operator Certification - Undergraduate Cyber Training - Keesler AFB, MS - Graduated: April 2016<br/>
            BS in Computer Engineering - United States Air Force Academy - Colorado Springs, CO - Graduated: May 2015<br/>
            </div>
            <div className={layoutStyle.content}>
            <h3>Clearances</h3>
            Top Secret Security Clearance (TS/SCI)
            </div>
        </Layout>
    )

}

export default ResumePage